import React from "react";
import Control from "../../../../common/Controls/Control";
import useFormState from "../../../hooks/useFormState";

const PaymentMethodSelector = () => {
  const { constants, actions, formData, errors } = useFormState();

  return (
    <>
      <Control
        label="Choisissez votre mode de paiement"
        type="btnList"
        name="paymentMode"
        datas={constants.PAYMENT_TYPES}
        dataIndex="id"
        dataLabel="name"
        btnInline
        value={formData.paymentMode}
        change={actions.updateFormDataInput}
        error={errors}
      />
    </>
  );
};

export default PaymentMethodSelector;
