import React, { useEffect } from "react";
import useFormState from "../../hooks/useFormState";

const Finish = () => {
  const { formData } = useFormState();

  useEffect(() => {
    window.scrollTo(0, 0);
    window.top.postMessage("Purchase", "*");
  }, []);

  return (
    <div className="d-flex flex-column flex-md-row align-items-start w-100 text-center">
      <div className="py-3 w-100">
        <img className="" src="/images/youpi.png" />
        {/* AJOUTER L'ID DE LA COMMANDE À LA PLACE DE 12334 */}
        <p style={{ fontWeight: "650" }}>VOTRE COMMANDE EST VALIDÉE !</p>
        <p className="p-3 mb-4 pb-0">
          Vos documents sont en cours de traitement.
          <br /> Vous recevrez dans les prochaines minutes, votre facture par
          email, ainsi
          <br /> qu'une notification lorsque vos documents seront disponibles
          dans votre
          <br /> espace LegaDrive.
        </p>
        {/* LINK LE BOUTON */}
        {formData.paymentMode == "SEPA" && (
          <div
            className="alert alert-secondary mx-auto mb-5"
            style={{ maxWidth: 500 }}
          >
            <i className="fa fa-exclamation-circle fa-2x mr-1" />
            <p className="mb-0">
              Vous aviez payé par prélèvement SEPA, votre demande sera donc
              traitée dès la réception du règlement, ce qui peut prendre
              quelques jours.
            </p>
          </div>
        )}
        <a
          href={process.env.REACT_APP_APP_URL}
          target="_BLANK"
          className="btn btn-primary px-3 mb-4 d-inline"
          style={{ fontWeight: "650" }}
        >
          Accéder à mon compte LegaDrive
        </a>
      </div>
    </div>
  );
};

export default Finish;
