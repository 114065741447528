import { useEffect, useState } from "react";
import dataService from "../../../../../helpers/dataService";
import useFormState from "../../../hooks/useFormState";

const useBillingAddresses = () => {
  const { actions, formData, errors } = useFormState();

  const [isLoadingAddresses, setIsLoadingAddresses] = useState(false);
  const [isAddingAddress, setIsAddingAddress] = useState(false);
  const [isCreatingAddress, setIsCreatingAddress] = useState(false);
  const [newAddress, setNewAddress] = useState({
    name: "",
    address1: "",
    address2: "",
    postalCode: "",
    city: "",
  });

  const handleChangeAddress = (e) => {
    const { name, value } = e.target;
    setNewAddress((newAddress) => ({
      ...newAddress,
      [name]: value,
    }));
  };

  const handleModifyAddress = () => {
    setIsAddingAddress(true);
    setNewAddress(formData.billingAddresses.find((addr) => addr.id == formData.selectedBillingAddress));
  };

  const getBillingAddresses = () => {
    setIsLoadingAddresses(true);
    dataService.get(
      `customer-addresses/mines/BILLING`,
      (datas) => {
        if (datas.length == 0) {
          setIsAddingAddress(true);
        } else {
          actions.updateFormDataValue({
            billingAddresses: datas,
          });
        }
      },
      actions.updateErrors,
      () => setIsLoadingAddresses(false)
    );
  };

  const addBillingAddress = () => {
    //ajouter l'envoi de la nouvelle adresse à l'api
    setIsCreatingAddress(true);
    dataService.post(
      `customer-addresses`,
      newAddress,
      (datas) => {
        getBillingAddresses();
        actions.updateFormDataValue({ selectedBillingAddress: datas.id });
        setIsAddingAddress(false);
      },
      actions.updateErrors,
      () => setIsCreatingAddress(false)
    );
  };
  const editBillingAddress = () => {
    //ajouter l'envoi de la nouvelle adresse à l'api
    setIsCreatingAddress(true);
    dataService.patch(
      `customer-addresses/${newAddress.id}`,
      newAddress,
      (datas) => {
        getBillingAddresses();
        actions.updateFormDataValue({ selectedBillingAddress: datas.id });
        setIsAddingAddress(false);
      },
      actions.updateErrors,
      () => setIsCreatingAddress(false)
    );
  };

  return {
    formData,
    actions,
    getBillingAddresses,
    errors,
    isAddingAddress,
    setIsAddingAddress,
    handleChangeAddress,
    newAddress,
    addBillingAddress,
    editBillingAddress,
    isLoadingAddresses,
    isCreatingAddress,
    setIsAddingAddress,
    handleModifyAddress,
  };
};

export default useBillingAddresses;
