import React, { useEffect } from "react";
import tools from "../../../../helpers/tools";
import useFormState from "../../hooks/useFormState";
import useLogin from "../../hooks/useLogin";

const LoggedInCard = (props) => {
  const { actions } = useFormState();
  const { user } = useLogin();

  useEffect(() => {
    actions.updateFormDataValue({
      userAccountData: user,
    });
  }, [user]);

  return (
    <div className="p-3 d-center flex-column w-100">
      <div>
        <strong className="text-center d-block" style={{ fontSize: 22 }}>
          Bienvenue, <span style={{ textTransform: "capitalize" }}>{tools.getFullname(user)}</span> 👋
        </strong>
      </div>

      {props.children}
    </div>
  );
};

export default LoggedInCard;
