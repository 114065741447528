import axios from "axios";
import { API_URL } from "../../config";
import tools from "../../helpers/tools";
import { CONSTANT_TYPES } from "../reducers/constantReducers";

export const constantActions = (state, dispatch, props) => {
  function getConstants() {
    dispatch({ type: CONSTANT_TYPES.LOAD_CONSTANT, payload: true });

    axios
      .get(API_URL + "constants/" + process.env.REACT_APP_PLATFORM)
      .then((res) => {
        dispatch({ type: CONSTANT_TYPES.GET_CONSTANTS, payload: res.data });
      })
      .catch((err) => {
        console.log(err);
        var err = tools.filterError(
          err,
          dispatch,
          CONSTANT_TYPES.LOAD_CONSTANT
        );
      });
  }

  return {
    getConstants,
  };
};
