import React from "react";
import Control from "../../../common/Controls/Control";
import SaveBtn from "../../../common/SaveBtn/SaveBtn";
import AutoConnect from "./AutoConnect";
import useAccount from "./useAccount";

const AccountForm = () => {
  const {
    actions,
    formData,
    formStatus,
    errors,
    createUser,
    isCreatingUser,
    userCreated,
  } = useAccount();

  return (
    formStatus.isCheckedUserExists &&
    !formStatus.isUserExists && (
      <>
        <form onSubmit={createUser} className="mt-4">
          <div className="row">
            <div className="col-12 col-md-6">
              <Control
                label="Choisissez un mot de passe"
                type="password"
                name="password"
                value={formData.password}
                change={actions.updateFormDataInput}
                error={errors}
              />
            </div>
            <div className="col-12 col-md-6">
              <Control
                label="Retapez le mot de passe"
                type="password"
                name="passwordConfirm"
                value={formData.passwordConfirm}
                change={actions.updateFormDataInput}
                error={errors}
              />
            </div>
            <div className="col-12 col-md-6">
              <Control
                label="Votre nom"
                type="text"
                name="lastname"
                value={formData.lastname}
                change={actions.updateFormDataInput}
                error={errors}
              />
            </div>
            <div className="col-12 col-md-6">
              <Control
                label="Votre prénom"
                type="text"
                name="firstname"
                value={formData.firstname}
                change={actions.updateFormDataInput}
                error={errors}
              />
            </div>

            <div className="col-12 col-md-6 offset-md-6 ">
              <Control
                label="J'accepte les conditions générales"
                type="checkbox"
                name="isOptinChecked"
                checked={formData.isOptinChecked}
                change={actions.updateFormDataInput}
                error={
                  errors
                    ? errors.termsOfService
                      ? { isOptinChecked: errors.termsOfService }
                      : {}
                    : {}
                }
              />
              <SaveBtn
                className="w-100"
                text="Créer mon compte"
                typeBtn="submit"
                isSaving={isCreatingUser || userCreated}
              />
            </div>
          </div>
        </form>
        {userCreated && <AutoConnect />}
      </>
    )
  );
};

export default AccountForm;
