import React from "react";
import Control from "../../../common/Controls/Control";
import SaveBtn from "../../../common/SaveBtn/SaveBtn";
import useSearchForm from "./useSearchForm";

const SearchForm = () => {
  const { handleSubmit, actions, formData, isLoading } = useSearchForm();

  return (
    <form onSubmit={handleSubmit} className="row mt-4">
      <div className="col-12 col-md-8 pe-md-0">
        <Control
          label="Rechercher le nom d'un entreprise, un SIREN/SIRET..."
          name="searchCompanyTerms"
          value={formData.searchCompanyTerms}
          change={actions.updateFormDataInput}
          type="text"
        />
      </div>
      <div className="col-12 col-md-4 justify-content-end d-flex align-items-end ps-md-0">
        <SaveBtn
          disabled={!formData.searchCompanyTerms}
          className="btn btn-primary mt-2 mb-4 w-100"
          text={
            <>
              <i className="fa fa-search me-2" />
              Rechercher
            </>
          }
          isSaving={isLoading}
          typeBtn="submit"
        />
      </div>
    </form>
  );
};

export default SearchForm;
