import React from "react";
import colors from "../../../../helpers/colors";
import tools from "../../../../helpers/tools";
import useSearchResults from "../../steps/SearchCompany/useSearchResults";

const CompanyMiniCard = ({ company, animationName, disabled, ...props }) => {
  const { unselectCompany } = useSearchResults();

  return (
    <div className={`my-3 ${disabled ? "" : "pb-3"} position-relative`}>
      <div
        layout
        className={`d-flex flex-row align-items-center w-100 position-relative justify-content-between border-bottom  animate__animated animate__faster ${animationName}`}
      >
        <div className={disabled ? "px-2 pt-2" : ""}>
          <div>
            <strong>{company.companyName}</strong>
          </div>
          <div>
            <small>{tools.formatSiren(company.siret)}</small>
          </div>
        </div>

        {!disabled && (
          <div>
            <i className="fa fa-trash text-danger p-1 cursor-pointer" onClick={() => unselectCompany(company)} />
          </div>
        )}
      </div>
      {disabled && (
        <>
          <div
            style={{
              position: "absolute",
              background: `repeating-linear-gradient(  -55deg,  ${colors.info},  ${colors.info} 10px,  ${colors.secondary} 10px,  ${colors.secondary} 20px)`,
              width: "100%",
              height: "100%",
              zIndex: "-1",
              opacity: "0.3",
              top: 0,
            }}
          ></div>
          <div className="d-flex align-items-center justify-content-start w-100 px-2 text-white bg-danger">
            <i className="fa fa-info-circle  me-2" />
            <small>Vous êtes déjà abonné à cette société</small>
          </div>
        </>
      )}
    </div>
  );
};

export default CompanyMiniCard;
