import React, { useRef, useState } from "react";
import LabelContainer from "../LabelContainer";
import ControlTools from "../ControlTools";
import EmptyButton from "./EmptyButton";

const NumberInput = ({
  k = -1,
  autoFocus,
  autocomplete = "on",
  inputStyle = {},
  inputClassName = "",
  type,
  disabled = false,
  id = null,
  value,
  change = () => {},
  placeholder = "",

  ...props
}) => {
  const [pulsing, setPulsing] = useState(props.pulsing);
  const inputRef = useRef(null);
  const valueChange = (e) => {
    var filteredValue = ControlTools.numberWithSpaces(e.target.value)
      .split(" ")
      .join("");
    filteredValue = parseInt(filteredValue, 10);
    //console.log(filteredValue);
    let changeEvent = {
      target: {
        name: props.name,
        value: filteredValue,
        k: k,
      },
    };

    change(changeEvent);
  };

  const formatValue = (value) => {
    var compareValue = value === 0 ? value : value ? value : [];
    return ControlTools.numberWithSpaces(compareValue);
  };

  const onFocus = (e) => {
    setPulsing(false);
    if (props.onFocus) {
      props.onFocus(e);
    }
  };

  return (
    <>
      <div
        className={`d-flex position-relative flex-column ${
          pulsing ? "pulsing-rect" : ""
        }`}
        onClick={() => {
          setPulsing(false);
          inputRef?.current?.focus();
        }}
      >
        <LabelContainer {...props} value={value}>
          <input
            ref={inputRef}
            autoFocus={autoFocus}
            onFocus={onFocus}
            onBlur={props.onBlur || null}
            autoComplete={autocomplete}
            style={inputStyle}
            className={`form-control ${inputClassName} `}
            pattern="[0-9]*"
            type="text"
            disabled={disabled ? "disabled" : false}
            id={k != -1 ? props.name + "-" + k : id ? id : props.name}
            name={props.name}
            value={formatValue(value)}
            onChange={valueChange}
            placeholder={placeholder}
          />
          <EmptyButton
            change={change}
            name={props.name}
            targetInput={inputRef}
            value={value}
          />
        </LabelContainer>
      </div>
    </>
  );
};

export default NumberInput;
