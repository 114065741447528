import { useAtom } from "jotai";
import React, { useState } from "react";
import { searchCompanyTermsAtom } from "../../../../atoms/atoms";
import dataService from "../../../../helpers/dataService";
import useFormState from "../../hooks/useFormState";

const useSearchForm = () => {
  const { formState, actions, formData } = useFormState();
  const [searchCompanyTerms, setSearchCompanyTerms] = useAtom(searchCompanyTermsAtom);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSearchCompanyTerms(formData.searchCompanyTerms);
    getCompanies();
  };

  const getCompanies = () => {
    actions.updateFormDataValue({
      searchCompanyResults: [],
    });
    setIsLoading(true);
    dataService.get(
      `companies/search/` + formData.searchCompanyTerms,
      (data) =>
        actions.updateFormDataValue({
          searchCompanyResults: data,
        }),
      (err) => {},
      () => setIsLoading(false)
    );
  };

  return {
    formState,
    actions,
    handleSubmit,
    isLoading,
    formData,
  };
};

export default useSearchForm;
