import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useState, useEffect } from "react";
import CheckoutForm from "./CheckoutForm/CheckoutForm";
import PaymentMethodsList from "./PaymentMethodsList";
import useFormState from "../../../hooks/useFormState";
import dataService from "../../../../../helpers/dataService";
import Loader from "../../../../common/Loader";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const PaymentMethodsForm = ({ totalPrice }) => {
  const { actions, formState } = useFormState();

  useEffect(() => {
    if (formState.isPaymentDone) {
      actions.goToStep(4);
    }
  }, [formState.isPaymentDone]);

  return (
    <div>
      <Elements stripe={stripePromise}>
        <CheckoutForm onPaymentDone={actions.finishForm}>
          <PaymentMethodsList />
        </CheckoutForm>
      </Elements>
    </div>
  );
};

export default PaymentMethodsForm;
