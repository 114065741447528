import React, { useEffect, useState } from "react";
import dataService from "../../../../helpers/dataService";
import tools from "../../../../helpers/tools";

const useSubscription = () => {
  const [price, setPrice] = useState({
    amount: 0,
  });

  const getGenericPrice = () => {
    dataService.get(`shop-subscriptions/display-prices`, (pr) =>
      setPrice({
        amount: tools.truenumberWithSpaces(pr.amount / 100),
      })
    );
  };

  useEffect(() => {}, []);

  return { price, getGenericPrice };
};

export default useSubscription;
