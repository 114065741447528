import React, { useEffect, useState } from "react";
import PaymentMethodsForm from "./PaymentMethodsForm";
import PaymentMethodSelector from "./PaymentMethodSelector";
import StepTitle from "../../../common/StepTitle/StepTitle";
import dataService from "../../../../../helpers/dataService";
import useFormState from "../../../hooks/useFormState";
import Loader from "../../../../common/Loader";

const PaymentMethod = ({ totalPrice }) => {
  const { actions, formState } = useFormState();

  const [isLoadingPaymentMethods, setIsLoadingPaymentMethods] = useState(true);

  useEffect(() => {
    getExistingPaymentMethods();
  }, []);

  const getExistingPaymentMethods = () => {
    setIsLoadingPaymentMethods(true);
    dataService.get(
      `payments/methods/mines`,
      (datas) => {
        // actions.updateFormDataValue({
        //   existingPaymentMethods: [{ id: "1", name: "123xxxx12", type: "CB" }],
        //   selectedExistingPaymentMethod: "",
        //   paymentMode: "",
        // });
        actions.updateFormDataValue({
          existingPaymentMethods: datas,
          selectedExistingPaymentMethod: "",
          paymentMode: "",
        });
      },
      (err) => {},
      () => setIsLoadingPaymentMethods(false)
    );
  };

  return (
    <div>
      {isLoadingPaymentMethods ? (
        <div className="mt-4">
          <Loader />
        </div>
      ) : (
        <>
          <StepTitle text="Paiement de votre commande" subText="" />

          <PaymentMethodSelector />
          <PaymentMethodsForm />
        </>
      )}
    </div>
  );
};

export default PaymentMethod;
