import { AnimatePresence, motion } from "framer-motion";
import { useAtom } from "jotai";
import { DateTime } from "luxon";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { isPriceLoadingAtom, priceAtom } from "../../../../atoms/atoms";
import tools from "../../../../helpers/tools";
import Loader from "../../../common/Loader";
import CompanyMiniCard from "../../common/CompanyMiniCard/CompanyMiniCard";
import useFormState from "../../hooks/useFormState";

const SelectedCompaniesList = ({
  displayNextButton = true,
  unavailableCompanies = [],
}) => {
  const { formData, dispatch, formState, actions } = useFormState();
  const { selectedCompanies } = formData;
  const hasSelectedCompanies = selectedCompanies.length > 0;
  const [price, setPrice] = useAtom(priceAtom);
  const [isPriceLoading, setIsPriceLoading] = useAtom(isPriceLoadingAtom);

  const selectedPMData = tools.getSelectedPMData(formData);
  const hasSub = tools.checkPMHasSub(selectedPMData);

  const updateIsCompaniesSelected = () => {
    actions.updateFormDataValue({
      isCompaniesSelected: true,
    });
  };

  useEffect(() => {
    if (
      !hasSelectedCompanies &&
      formState.navigation.currentStep != "SearchCompany"
    ) {
      toast.info("Aucune société dans votre panier.");
      dispatch({
        type: "UPDATE_STEP",
        payload: "SearchCompany",
      });
    }
  }, [selectedCompanies]);

  return (
    <AnimatePresence>
      {hasSelectedCompanies > 0 && (
        <motion.div
          initial={{ maxWidth: "0%", opacity: 0, scale: 0 }}
          animate={{ maxWidth: "min(320px, 100%)", opacity: 1, scale: 1 }}
          exit={{ maxWidth: "0%", opacity: 0, scale: 0 }}
          transition={{ ease: "easeOut" }}
          style={{
            position: "sticky",
            top: 0,
            overflow: "hidden",
          }}
        >
          <div className="p-0 py-md-5 ms-md-4 ps-md-4 ">
            <div style={{ fontSize: 25, fontWeight: "700" }}>Récapitulatif</div>
            <p>Retrouvez ici toutes les entreprises sélectionées</p>
            {selectedCompanies.map((company, ck) => (
              <CompanyMiniCard
                disabled={unavailableCompanies.find(
                  (c) => c.siren == company.siren
                )}
                animationName={ck > 0 ? "animate__fadeInUp" : ""}
                key={`compminicard${company.siret}`}
                company={company}
                nbSelected={selectedCompanies.length}
              />
            ))}
            {displayNextButton && (
              <button
                className={`btn btn-primary my-3 w-100`}
                onClick={updateIsCompaniesSelected}
              >
                Suivant
                <i className="fa fa-chevron-right ms-2" />
              </button>
            )}
            {price.totalWithoutPromo &&
              (isPriceLoading ? (
                <Loader />
              ) : (
                <div className="d-flex flex-row flex-wrap align-items-center justify-content-between">
                  <strong>Total :</strong>

                  <strong>
                    {price.totalWithoutPromo != price.total && (
                      <del
                        className="me-2 text-default"
                        style={{
                          fontWeight: "400",
                        }}
                      >
                        {tools.truenumberWithSpaces(
                          price.totalWithoutPromo / 100
                        )}{" "}
                        €
                      </del>
                    )}
                    {tools.truenumberWithSpaces(price.total / 100)} €/mois
                  </strong>
                  {hasSub && (
                    <i className="text-primary">
                      prélevés à la prochaine échéance de votre abonnement le{" "}
                      {DateTime.fromISO(
                        selectedPMData.subscription.echeance
                      ).toFormat("dd/MM/yyyy")}
                    </i>
                  )}
                </div>
              ))}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default SelectedCompaniesList;
