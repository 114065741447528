import React, { useEffect } from "react";
import NextButton from "../../common/NextButton/NextButton";
import StepTitle from "../../common/StepTitle/StepTitle";
import useFormState from "../../hooks/useFormState";
import useLogin from "../../hooks/useLogin";
import AccountForm from "./AccountForm";
import LoggedInCard from "./LoggedInCard";
import LoginForm from "./LoginForm";

const Account = () => {
  const { isAuthenticated } = useLogin();

  useEffect(() => {
    console.log("emit DisplayConnexion");
    window.top.postMessage("DisplayConnexion", "*");
  }, []);

  return (
    <div>
      <div className="d-flex flex-column flex-md-row align-items-start w-100">
        <div className="py-3 mb-4 w-100">
          <StepTitle
            text="Connexion à votre compte"
            subText="Connectez-vous ou créez votre compte pour finaliser votre commande."
          />
        </div>
      </div>
      {isAuthenticated ? (
        <LoggedInCard>
          <NextButton fullWidth={false} />
        </LoggedInCard>
      ) : (
        <>
          <LoginForm />
          <AccountForm />
        </>
      )}
    </div>
  );
};

export default Account;
