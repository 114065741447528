import { useEffect, useState } from "react";
import dataService from "../../../../helpers/dataService";
import useFormState from "../../hooks/useFormState";

const useAccount = () => {
  const { state, dispatch, formData, actions, formStatus, errors } =
    useFormState();
  const [isCreatingUser, setIsCreatingUser] = useState(false);
  const [isCheckingUserExists, setIsCheckingUserExists] = useState(false);

  const [userCreated, setUserCreated] = useState(false);

  const checkUserExists = (e) => {
    e.preventDefault();
    setIsCheckingUserExists(true);
    dataService.post(
      `user-customers/exists`,
      { email: formData.email },
      (datas) => {
        actions.updateFormStatusValue({
          isCheckingUserExists: false,
          isCheckedUserExists: true,
          isUserExists: datas.isExists,
        });
      },
      (err) => {
        actions.updateErrors(err);
      },
      () => {
        setIsCheckingUserExists(false);
      }
    );
  };

  useEffect(() => {
    actions.updateFormStatusValue({
      isCheckingUserExists: false,
      isCheckedUserExists: false,
      isUserExists: false,
    });
    setIsCheckingUserExists(false);
  }, [formData.email]);

  const createUser = (e) => {
    e.preventDefault();
    if (formData.password != formData.passwordConfirm) {
      actions.updateErrors({
        passwordConfirm: "Les mots de passes ne sont pas identiques",
      });

      return false;
    }
    setIsCreatingUser(true);
    dataService.post(
      `user-customers`,
      {
        email: formData.email,
        password: formData.password,
        firstname: formData.firstname,
        lastname: formData.lastname,
        termsOfService: formData.isOptinChecked,
      },
      (datas) => {
        actions.updateFormDataValue({
          userAccountData: datas,
        });
        setUserCreated(true);
      },
      (err) => {
        console.log("ERRRR", err);
        actions.updateErrors(err);
      },
      () => {
        setIsCreatingUser(false);
      }
    );
  };

  return {
    formData,
    actions,
    checkUserExists,
    formStatus,
    errors,
    createUser,
    isCreatingUser,
    userCreated,
    isCheckingUserExists,
  };
};

export default useAccount;
