import React, { useEffect } from "react";
import NextButton from "../../common/NextButton/NextButton";
import StepTitle from "../../common/StepTitle/StepTitle";
import useFormState from "../../hooks/useFormState";
import SearchForm from "./SearchForm";
import SearchResults from "./SearchResults";
import SelectedCompaniesList from "./SelectedCompaniesList";
import { atom, useAtom } from "jotai";

const displayRecapEventSentAtom = atom(false);

const SearchCompany = () => {
  const { formData, actions } = useFormState();
  const { isCompaniesSelected } = formData;
  const [displayRecapEventSent, setDisplayRecapEventSent] = useAtom(
    displayRecapEventSentAtom
  );
  // const updateIsCompaniesSelected = () => {
  //   actions.updateFormDataValue({
  //     isCompaniesSelected: false,
  //   });
  // };

  useEffect(() => {
    if (isCompaniesSelected && !displayRecapEventSent) {
      setDisplayRecapEventSent(true);
      console.log("emit DisplayRecap");
      window.top.postMessage("DisplayRecap", "*");
    }
  }, [isCompaniesSelected]);

  const stepTitleOptions = {
    text: isCompaniesSelected ? "Récapitulatif" : "Rechercher votre société",
    subText: isCompaniesSelected
      ? "Retrouvez toutes les entreprises sélectionnées ainsi que les types de documents disponibles."
      : "Depuis la barre ci-dessous, faites une recherche pour trouver l’entreprise et ajoutez-la à votre panier. ",
  };

  return (
    <>
      <div className="d-flex flex-column flex-md-row align-items-start w-100">
        <div className="py-3 w-100">
          <StepTitle {...stepTitleOptions} />
          {!isCompaniesSelected && <SearchForm />}
          <SearchResults />
        </div>

        {!isCompaniesSelected && <SelectedCompaniesList />}
      </div>
      {isCompaniesSelected && (
        <div className="d-flex justify-content-end">
          <NextButton fullWidth={false} />
        </div>
      )}
    </>
  );
};

export default SearchCompany;
