import React from "react";
import useFormState from "../../hooks/useFormState";
import NavigationBlock from "./NavigationBlock";

const Navigation = () => {
  const { navigation } = useFormState();

  const blockNames = {
    SearchCompany: "Recherche",
    Subscription: "Abonnement",
    Account: "Compte",
    Payment: "Paiement",
    Finish: "Validation",
  };

  return navigation.currentStep == "Finish" ? null : (
    <div className="py-4 text-center bg-light">
      <ul className="d-flex flex-row px-md-2 mb-0 justify-content-center px-0">
        {navigation.steps.map((block, bk) => {
          const isActive = navigation.currentStep == block;
          const isComplete = navigation.steps.indexOf(navigation.currentStep) > bk;
          const blockName = blockNames[block];
          return bk < navigation.steps.length - 1 ? (
            <li className="d-flex flex-fill" style={{ maxWidth: 375 }} key={`navblock${block}`}>
              <NavigationBlock
                className={bk == 0 ? "first" : bk == navigation.steps.length - 2 ? "last" : ""}
                blockName={blockName}
                blockIndex={bk}
                isActive={isActive}
                isComplete={isComplete}
              />
            </li>
          ) : null;
        })}
      </ul>
    </div>
  );
};

export default Navigation;
