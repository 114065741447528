import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import useFormState from "../../hooks/useFormState";
import DisplayStep from "../DisplayStep/DisplayStep";

const PreviousButton = ({ text }) => {
  const { actions, navigation } = useFormState();

  return (
    navigation.currentStep != "SearchCompany" &&
    navigation.currentStep != "Finish" && (
      <>
        <button
          className="btn ps-0 d-center flex-row"
          onClick={actions.previousStep}
          style={{ position: "absolute", top: -10 }}
        >
          <i className="fa fa-chevron-left me-2" />
          <DisplayStep />
        </button>
      </>
    )
  );
};

export default PreviousButton;
