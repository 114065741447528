import React from "react";
import NewCBForm from "./NewPaymentMethodForm/NewCBForm";
import NewSEPAForm from "./NewPaymentMethodForm/NewSEPAForm";
import useFormState from "../../../hooks/useFormState";

const NewPaymentMethod = () => {
  const { formData } = useFormState();

  return (
    <div>
      {formData.paymentMode == "CB" && <NewCBForm />}
      {formData.paymentMode == "SEPA" && <NewSEPAForm />}
    </div>
  );
};

export default NewPaymentMethod;
