import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";
import CompanyCard from "../../common/CompanyCard/CompanyCard";
import CompanyDocuments from "./CompanyDocuments";
import useSearchResults from "./useSearchResults";
const SearchResults = () => {
  const { formData, selectCompany } = useSearchResults();

  let isCompaniesSelected = formData.isCompaniesSelected;

  let companyList = isCompaniesSelected
    ? formData.selectedCompanies
    : formData.searchCompanyResults;

  const handleCompanyClick = (company) => {
    if (isCompaniesSelected) return;
    if (formData.selectedCompanies.length == 20) {
      toast.info("Vous ne pouvez pas sélectionner plus de 20 sociétés");
      return;
    }

    window.top.postMessage("AddToCart", "*");

    selectCompany(company);
  };

  useEffect(() => {
    if (isCompaniesSelected) {
      try {
        window.scrollTo(0, 0);
      } catch (err) {}
    }
  }, [isCompaniesSelected]);

  return (
    <div>
      {companyList.map((company, ck) => (
        <CompanyCard
          key={`comp${company.siret}`}
          company={company}
          clickable={!isCompaniesSelected}
          isSelected={formData.selectedCompanies.find(
            (c) => c.siret == company.siret
          )}
          onClick={() => handleCompanyClick(company)}
          style={{ animationDelay: parseInt(ck) * 50 + "ms" }}
        >
          {isCompaniesSelected && <CompanyDocuments company={company} />}
        </CompanyCard>
      ))}
    </div>
  );
};

export default SearchResults;
