import React from "react";
import { IbanElement, PaymentElement } from "@stripe/react-stripe-js";

const IBAN_STYLE = {
  base: {
    color: "#32325d",
    fontFamily: "Monserrat, sans-serif",
    fontSmoothing: "antialiased",
    lineHeight: "26px",
    fontSize: "16px",
    "::placeholder": {
      color: "#aab7c4",
    },
  },
  invalid: {
    color: "#fa755a",
    iconColor: "#fa755a",
  },
};

const IBAN_ELEMENT_OPTIONS = {
  supportedCountries: ["SEPA"],
  // Elements can use a placeholder as an example IBAN that reflects
  // the IBAN format of your customer's country. If you know your
  // customer's country, we recommend that you pass it to the Element as the
  // placeholderCountry.
  placeholderCountry: "FR",
  style: IBAN_STYLE,
};

function NewSEPAForm() {
  return (
    <div className="row">
      {/* <div className="col-12">
        <label className="w-100">
          Numéro de carte
          <CardElement options={CARD_ELEMENT_OPTIONS} />
        </label>
      </div> */}

      <div className="col-12">
        <label className="w-100">
          Numéro IBAN
          <IbanElement options={IBAN_ELEMENT_OPTIONS} />
        </label>
      </div>
    </div>
  );
}

export default NewSEPAForm;
