import { useAtom } from "jotai";
import { searchCompanyTermsAtom, selectedCompaniesAtom } from "../../../atoms/atoms";
import useFormState from "./useFormState";

const useRestore = () => {
  const { actions } = useFormState();
  const [selectedCompanies] = useAtom(selectedCompaniesAtom);
  const [searchCompanyTerms] = useAtom(searchCompanyTermsAtom);

  const restoreFormState = () => {
    actions.updateFormDataValue({
      selectedCompanies: selectedCompanies,
    });

    actions.updateFormDataInput({
      target: {
        name: "searchCompanyTerms",
        value: searchCompanyTerms,
      },
    });
  };

  return { restoreFormState };
};

export default useRestore;
