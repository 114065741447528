import React, { useEffect } from "react";
import StepTitle from "../../common/StepTitle/StepTitle";
import useFormState from "../../hooks/useFormState";
import SelectedCompaniesList from "../SearchCompany/SelectedCompaniesList";
import BillingAddresses from "./BillingAddresses/BillingAddresses";
import PaymentMethod from "./PaymentMethod/PaymentMethod";
import PromoCode from "./PromoCode/PromoCode";
import usePrices from "./usePrices";

const Payment = () => {
  const { formData, actions } = useFormState();
  const { getPrices, prices, unavailableCompanies } = usePrices();

  useEffect(() => {
    if (formData.selectedCompanies.length > 0 && formData.paymentMode)
      getPrices();
  }, [
    formData.selectedCompanies,
    formData.selectedExistingPaymentMethod,
    formData.paymentMode,
  ]);

  useEffect(() => {
    console.log("emit DisplayPayment");
    window.top.postMessage("DisplayPayment", "*");
  }, []);

  const resetSelectedCompanies = () => {
    actions.updateFormDataValue({ selectedCompanies: [] });
  };

  const allCompaniesUnavailable =
    unavailableCompanies.length == formData.selectedCompanies.length;

  return (
    <div className="d-flex flex-column flex-md-row align-items-start w-100">
      {allCompaniesUnavailable ? (
        <div className="py-3 w-100">
          <div className="alert alert-danger">
            Les sociétés sélectionnées sont déjà présentes dans votre
            abonnement.
            <button
              className="btn btn-primary mt-2"
              onClick={resetSelectedCompanies}
            >
              <i className="fa fa-arrow-left me-2" />
              Revenir à la sélection des sociétés
            </button>
          </div>
        </div>
      ) : (
        <div className="py-3 w-100">
          <div
            className="opacity-on-hover"
            style={{
              opacity: formData.selectedBillingAddress ? "0.3" : "1",
              transition: "opacity 800ms",
            }}
          >
            <StepTitle text="Adresse de facturation" subText="" />
            <div className="mt-3">
              <BillingAddresses />
            </div>
          </div>

          {formData.selectedBillingAddress && (
            <div className="mt-3 mt-4 border-top pt-4">
              <PromoCode />
            </div>
          )}
          {formData.selectedBillingAddress && (
            <div className="mt-3 mt-4 border-top pt-4">
              <PaymentMethod totalPrice={prices?.total || ""} />
            </div>
          )}
        </div>
      )}
      <SelectedCompaniesList
        displayNextButton={false}
        totalPrice={isNaN(prices?.total) ? "" : prices?.total}
        unavailableCompanies={unavailableCompanies}
      />
    </div>
  );
};

export default Payment;
