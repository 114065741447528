import React, { useEffect } from "react";
import NextButton from "../../common/NextButton/NextButton";
import StepTitle from "../../common/StepTitle/StepTitle";
import PriceCard from "./PriceCard";
import useSubscription from "./useSubscription";

const Subscription = () => {
  const { price, getGenericPrice } = useSubscription();

  useEffect(() => {
    getGenericPrice();
    console.log("emit DisplaySubscription");
    window.top.postMessage("DisplaySubscription", "*");
  }, []);

  return (
    <div>
      <div className="row">
        <div className="py-3 col-12 col-md-6">
          <StepTitle
            text="Votre abonnement"
            subText={
              <>
                Inclut l'accès aux informations légales, juridiques et
                financières de votre entreprise (statuts, comptes sociaux,
                dirigeants, marques, etc.)<sup>(1)</sup>.
              </>
            }
          />
        </div>
        <div className="p-3 col-12 col-md-6">
          <PriceCard price={price.amount} />
        </div>
      </div>
      <p className="text-default mt-3">
        (1) Les documents sont collectés auprès des services de l'État et sont
        constamment actualisés. Le nombre de documents disponibles peut varier
        selon votre type de structure (micro-entreprise, SARL, EURL, SAS, etc.).
      </p>
    </div>
  );
};

export default Subscription;
