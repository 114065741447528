import React from "react";

const FormWrapper = (props) => {
  return (
    <div
      className="border rounded mx-auto"
      style={{
        maxWidth: 1200,
      }}
    >
      {props.children}
    </div>
  );
};

export default FormWrapper;
