import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import useFormState from "../../hooks/useFormState";

const NextButton = ({ text, fullWidth = true }) => {
  const { actions } = useFormState();

  return (
    <AnimatePresence>
      <motion.button
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 20, opacity: 0 }}
        transition={{ duration: 0.4 }}
        className={`btn btn-primary my-3 ${fullWidth ? "w-100" : ""}`}
        onClick={actions.nextStep}
      >
        {text || "Suivant"}
        <i className="fa fa-chevron-right ms-2" />
      </motion.button>
    </AnimatePresence>
  );
};

export default NextButton;
