import React, { useEffect, useState } from "react";
import { loadReCaptcha, ReCaptcha } from "react-recaptcha-v3";
import { APP_URL, recaptcha_token } from "../../../../config";
import { authActions } from "../../../../context/actions/authActions";
import SaveBtn from "../../../common/SaveBtn/SaveBtn";
import useFormState from "../../hooks/useFormState";

var recaptcha;

const Login = ({ autoConnect = false, ...props }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(false);
  const { state, dispatch, actions, formData } = useFormState();
  const auth_actions = authActions(state, dispatch);

  useEffect(() => {
    loadReCaptcha(recaptcha_token, () => {});
  }, []);

  useEffect(() => {
    if (state.errors) {
      actions.updateErrors(state.errors);
    }
    setIsLoading(false);
  }, [state.auth.isAuthenticated, state.errors]);

  function handleLogin(e) {
    if (isLoading) return;
    e.preventDefault();
    setIsLoading(true);
  }
  const verifyCallback = (recaptchaToken) => {
    // Here you will get the final recaptchaToken!!!

    auth_actions.loginUser({
      login: formData.email,
      password: formData.password,
      captchaToken: recaptchaToken,
    });
    setCaptchaToken(recaptchaToken);
  };

  const updateToken = () => {
    // you will get a new token in verifyCallback
    if (recaptcha) {
      recaptcha.execute();
    }
  };
  return (
    <form onSubmit={handleLogin}>
      {props.children}
      {!autoConnect && (
        <>
          <SaveBtn typeBtn="submit" isSaving={isLoading} text="Se connecter" />
          <a target="_blank" href={`${APP_URL}?forget-pwd`} className="btn btn-link px-0">
            Mot de passe oublié ?
          </a>
        </>
      )}
      {(isLoading || autoConnect) && (
        <ReCaptcha
          ref={(ref) => (recaptcha = ref)}
          sitekey={recaptcha_token}
          action="login"
          verifyCallback={verifyCallback}
        />
      )}
    </form>
  );
};

export default Login;
