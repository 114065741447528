import React from "react";

const DocumentCheck = ({ name, isAvailable }) => {
  return (
    <div className={`d-flex flex-row align-items-baseline `} style={{ fontSize: 16 }}>
      <i className={`fa me-2 ${isAvailable ? "fa-check text-success" : "fa-times text-default"}`} />
      <span className="text-default">{name}</span>
    </div>
  );
};

export default DocumentCheck;
