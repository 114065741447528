import React from "react";
import { HashLoader } from "react-spinners";
import colors from "../../helpers/colors";

const Loader = ({
    size = 16,
    color = colors.primary,
    textColor = colors.primary,
    withContainer = false,
    containerStyle = {},
    text = false,
    className = "",
}) => (
    <div
        className={
            className +
            "  d-flex align-items-center justify-content-center flex-column " +
            (withContainer ? "w-100 " : "")
        }
        style={containerStyle}
    >
        <HashLoader color={color} size={size} />
        {text && (
            <strong
                className={` mt-2`}
                style={{
                    color: textColor,
                }}
            >
                {text}
            </strong>
        )}
    </div>
);

export default Loader;
