import { useEffect } from "react";
import { PLATFORM } from "../../../config";
import useStore from "../../../context/useStore";
import dataService from "../../../helpers/dataService";
import jwt_decode from "jwt-decode";
import { authActions } from "../../../context/actions/authActions";
import { AUTH_TYPES } from "../../../context/reducers/authReducers";
var firstLoad = true;
const useAuth = () => {
  const [state, dispatch] = useStore();
  const auth_actions = authActions(state, dispatch);
  useEffect(() => {
    if (localStorage.jwtToken && firstLoad) {
      firstLoad = false;
      // Set auth token header auth
      //tools.setAuthToken(localStorage.jwtToken);
      // Decode token and get user info and exp
      const decoded = jwt_decode(localStorage.jwtToken);

      // Set user and isAuthenticated
      dispatch({
        type: AUTH_TYPES.SET_CURRENT_USER,
        payload: decoded,
      });

      // Check for expired token
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        // Logout user

        auth_actions.logoutUser();
        // Clear current Profile
        // store.dispatch(clearCurrentProfile())
        // Redirect to login
      }
    }
  }, []);

  const refreshToken = () => {
    dataService.get(
      `auth/me`,
      (data) => {
        const decoded = jwt_decode(data);
        dispatch({
          type: AUTH_TYPES.SET_CURRENT_USER,
          payload: decoded,
        });
      },
      (err) => auth_actions.logoutUser(true)
    );
  };

  return { auth_actions, refreshToken };
};

export default useAuth;
