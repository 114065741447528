import { useAtom } from "jotai";
import { DateTime } from "luxon";
import React from "react";
import { isPriceLoadingAtom, priceAtom } from "../../../../../../atoms/atoms";
import tools from "../../../../../../helpers/tools";
import useFormState from "../../../../hooks/useFormState";

const ExistingSubAlert = ({ selectedPMData }) => {
  const { formData } = useFormState();
  const [price, setPrice] = useAtom(priceAtom);
  const [isPriceLoading, setIsPriceLoading] = useAtom(isPriceLoadingAtom);

  return (
    <div className="alert alert-info mt-3 text-secondary" style={{ lineHeight: "18px" }}>
      <p className="mb-2">
        <small>La méthode de paiement sélectionnée est rattachée à un de vos abonnements :</small>
      </p>
      <ul className="pb-0 mb-0 ps-0" style={{ listStyle: "none" }}>
        <li className="text-center" style={{ fontWeight: "700" }}>
          Abonnement à {tools.round(selectedPMData.subscription.amount / 100)}
          <sup>€/mois</sup> échéance le{" "}
          {DateTime.fromISO(selectedPMData.subscription.echeance).toFormat("dd/MM/yyyy")}
        </li>
      </ul>
      <p className="mb-2 pt-2">
        <small>
          Les coffres de cette nouvelle commande seront donc rattachés à ce même abonnement, qui
          contiendra les coffres suivants :
        </small>
      </p>
      <ul className="pb-0 mb-2 ps-0" style={{ listStyle: "none" }}>
        {selectedPMData.subscription?.chests.map((chest, ck) => (
          <li className=" " key={`abochest${ck}`}>
            <small>
              <span className="badge bg-secondary text-white me-2">existant</span>
              {chest}
            </small>
          </li>
        ))}
        {formData.selectedCompanies.map((company) => (
          <li className="" key={`abonewchest${company.siret}`}>
            <small className="text-primary">
              <span className="badge bg-primary text-white me-2">nouveau</span>
              {company.companyName} - {company.siren}
            </small>
          </li>
        ))}
      </ul>
      <p className="mb-0 mt-3 pt-2">
        <small>
          L'ajout des nouveaux coffres sera facturé à la prochaine échéance de votre abonnement le{" "}
          <strong>
            {DateTime.fromISO(selectedPMData.subscription.echeance).toFormat("dd/MM/yyyy")}
          </strong>
          , pour un montant total de{" "}
          {isPriceLoading ? (
            "..."
          ) : (
            <strong>
              {tools.round(price.total / 100)}
              €/mois TTC
            </strong>
          )}
          .
        </small>
      </p>
    </div>
  );
};

export default ExistingSubAlert;
