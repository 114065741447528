import React from "react";

const StripeBadge = () => {
  return (
    <div className="d-flex justify-content-start flex-row mt-3">
      <img
        src="/images/stripe-badge.svg"
        style={{
          width: 100,
          height: 23,
        }}
      />
    </div>
  );
};

export default StripeBadge;
