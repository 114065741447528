import React, { useEffect } from "react";

const PasswordControl = ({
  minLength = 0,
  maxLength = 0,
  minLowercase = 0,
  minUppercase = 0,
  minSpecial = 0,
  minNumber = 0,
  password,
  setCanSave,
  style,
}) => {
  useEffect(() => {
    setCanSave({
      isComplete:
        minLengthOk() && minLowercaseOk() && minUppercaseOk() && minNumberOk(),
      icon: getIcon(),
    });
  }, [password]);

  function getIcon() {
    let icons = ["", "lock-open", "unlock", "lock", "shield-alt"];
    var strength = 0;
    strength += minLengthOk() ? 1 : 0;
    strength += minLowercaseOk() ? 1 : 0;
    strength += minUppercaseOk() ? 1 : 0;
    strength += minNumberOk() ? 1 : 0;
    return icons[strength];
  }

  function minLengthOk() {
    return password.length >= minLength;
  }
  function minLowercaseOk() {
    return password.replace(/[^a-z]/g, "").length >= minLowercase;
  }
  function minUppercaseOk() {
    return password.replace(/[^A-Z]/g, "").length >= minUppercase;
  }
  function minNumberOk() {
    return password.replace(/[^0-9]/g, "").length >= minUppercase;
  }
  function minSpecialOk() {
    var specialChars = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/gi;
    return (password.match(specialChars) || []).length >= minSpecial;
  }

  return (
    <ul
      className="d-flex flex-row justify-content-start flex-wrap w-100 flex-fill mb-0 mt-2"
      style={{ ...style, padding: 0, listStyle: "none", fontWeight: "400" }}
    >
      {minLength > 0 && (
        <li className="me-2">
          <span className={minLengthOk() ? "text-success" : "text-default"}>
            {minLength} caractères
          </span>
        </li>
      )}
      {minLowercase > 0 && (
        <li className="me-2">
          <span className={minLowercaseOk() ? "text-success" : "text-default"}>
            {minLowercase} minuscule{minLowercase > 1 && "s"}
          </span>
        </li>
      )}
      {minUppercase > 0 && (
        <li className="me-2">
          <span className={minUppercaseOk() ? "text-success" : "text-default"}>
            {minUppercase} majuscule{minUppercase > 1 && "s"}
          </span>
        </li>
      )}
      {minNumber > 0 && (
        <li className="me-2">
          <span className={minNumberOk() ? "text-success" : "text-default"}>
            {minNumber} chiffre{minNumber > 1 && "s"}
          </span>
        </li>
      )}
      {minSpecial > 0 && (
        <li className="me-2">
          <span className={minSpecialOk() ? "text-success" : "text-default"}>
            {minSpecial} caractère{minSpecial > 1 && "s"} spéci
            {minSpecial > 1 ? "aux" : "al"}
          </span>
        </li>
      )}
    </ul>
  );
};

export default PasswordControl;
