import React from "react";
import Control from "../../../common/Controls/Control";
import SaveBtn from "../../../common/SaveBtn/SaveBtn";
import useAccount from "./useAccount";
import LoginButton from "./LoginButton";

const LoginForm = () => {
  const {
    formData,
    actions,
    errors,
    checkUserExists,
    formStatus,
    isCheckingUserExists,
  } = useAccount();

  const emailChecked = formStatus.isCheckedUserExists;
  const userHasAccount = emailChecked && formStatus.isUserExists;

  return (
    <div className="row">
      <div className="col-12 col-md-6">
        <form onSubmit={checkUserExists}>
          <Control
            label="Email"
            type="email"
            value={formData.email}
            name="email"
            change={actions.updateFormDataInput}
            error={errors}
            suffix={
              formStatus.isCheckedUserExists ? null : (
                <SaveBtn
                  text="Valider"
                  typeBtn="submit"
                  isSaving={isCheckingUserExists}
                />
              )
            }
          />
        </form>
      </div>
      {userHasAccount ? (
        <div className="col-12 col-md-6">
          <LoginButton email={formData.email} password={formData.password}>
            <Control
              label="Mot de passe"
              type="password"
              value={formData.password}
              name="password"
              change={actions.updateFormDataInput}
              error={errors}
            />
          </LoginButton>
        </div>
      ) : emailChecked ? (
        <div className="col-12 col-md-6 d-center">
          <p className="text-default text-md-center mb-0">
            Vous n'avez pas encore de compte Legadrive.
          </p>
        </div>
      ) : null}
    </div>
  );
};

export default LoginForm;
