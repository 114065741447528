import React from "react";
import useFormState from "../../../hooks/useFormState";
import ExistingPaymentMethod from "./ExistingPaymentMethod";
import NewPaymentMethod from "./NewPaymentMethod";

const PaymentMethodsList = () => {
  const { formData } = useFormState();

  const { existingPaymentMethods, selectedExistingPaymentMethod } = formData;

  return (
    <div>
      {existingPaymentMethods.length > 0 && <ExistingPaymentMethod />}
      {!selectedExistingPaymentMethod && <NewPaymentMethod />}
    </div>
  );
};

export default PaymentMethodsList;
