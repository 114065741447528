import useStore from "../../../context/useStore";

const useLogin = () => {
  const [state, dispatch] = useStore();

  return {
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
  };
};

export default useLogin;
