import React, { useEffect } from "react";
import Loader from "react-spinners/BarLoader";
import DocumentCheck from "./DocumentCheck";
import useSearchResults from "./useSearchResults";

const CompanyDocuments = ({ company }) => {
  const { getDocuments, documents } = useSearchResults();

  useEffect(() => {
    if (!documents) {
      getDocuments(company);
    }
  }, []);

  const allDocuments = [
    { code: "kbis", name: "KBIS (1/mois)" },
    { code: "annualResults", name: "Comptes annuels" },
    { code: "rbe", name: "RBE" },
    { code: "inseeSituationNotice", name: "Avis situation SIRENE" },
    { code: "inpiSituationNotice", name: "Extrait immatriculation RCS" },
    { code: "acts", name: "Actes (status, PV AGE, constitutions, modifications, etc)" },
  ];
  const availableDocuments = allDocuments.filter((doc) => documents[doc.code]);
  const unavailableDocuments = allDocuments.filter((doc) => !documents[doc.code]);
  console.log("availableDocuments", availableDocuments);
  console.log("unavailableDocuments", unavailableDocuments);

  return (
    <div>
      {!documents && (
        <div className="mt-4 d-center flex-column">
          <Loader />
          <small>recherche de documents</small>
        </div>
      )}
      {documents && (
        <div className="row">
          <div className="col-12 my-3">
            <strong className="d-block">DOCUMENTS DISPONIBLES</strong>
            <small className="text-default">
              Les documents qui seront récupérés pour votre société.
            </small>
          </div>
          {availableDocuments.map((doc) => (
            <div className="col-12 col-md-4" key={`avdoc${doc.code}`}>
              <DocumentCheck name={doc.name} isAvailable={true} />
            </div>
          ))}
          <div className="col-12 my-3">
            <strong className="d-block ">DOCUMENTS INDISPONIBLES</strong>
            <small className="text-default">
              Les documents qui ne pourrons pas être récupérés car ils ne sont pas disponibles ou
              n'existent pas pour votre société.
            </small>
          </div>
          {unavailableDocuments.map((doc) => (
            <div className="col-12 col-md-4" key={`avdoc${doc.code}`}>
              <DocumentCheck name={doc.name} isAvailable={false} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CompanyDocuments;
