import React from "react";

const CheckCircle = ({ ...props }) => {
  return (
    <i
      className={`fa fa-check text-white bg-success rounded-circle p-1 animate__animated ${
        props.animationClass || "animate__fadeInLeft"
      } animate__faster ${props.className}`}
      style={{
        position: "absolute",
        top: -5,
        right: -5,
        ...props.style,
      }}
    />
  );
};

export default CheckCircle;
