import React from "react";

import {
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: "Avenir, sans-serif",
      fontSmoothing: "antialiased",
      lineHeight: "26px",
      borderRadius: "10px",
      border: "1px solid",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#e51c23",
      iconColor: "#e51c23",
    },
  },
};
var cardBrandToPfClass = {
  visa: "pf-visa",
  mastercard: "pf-mastercard",
  amex: "pf-american-express",
  discover: "pf-discover",
  diners: "pf-diners",
  jcb: "pf-jcb",
  unknown: "pf-credit-card",
};

const NewCBForm = () => {
  const setBrandIcon = (brand) => {
    var brandIconElement = document.getElementById("brand-icon");
    var pfClass = "pf-credit-card";
    if (brand in cardBrandToPfClass) {
      pfClass = cardBrandToPfClass[brand];
    }
    for (var i = brandIconElement.classList.length - 1; i >= 0; i--) {
      brandIconElement.classList.remove(brandIconElement.classList[i]);
    }
    brandIconElement.classList.add("pf");
    brandIconElement.classList.add(pfClass);
  };
  const cardElementChange = (e) => {
    console.log(e);
    if (e.brand) {
      setBrandIcon(e.brand);
    }
  };
  return (
    <div className="row">
      {/* <div className="col-12">
        <label className="w-100">
          Numéro de carte
          <CardElement options={CARD_ELEMENT_OPTIONS} />
        </label>
      </div> */}
      <div className="col-12">
        <label className="w-100 position-relative">
          Numéro de carte
          <CardNumberElement
            options={CARD_ELEMENT_OPTIONS}
            onChange={cardElementChange}
          />
          <span
            className="brand d-center"
            style={{
              position: "absolute",
              bottom: 23,
              right: 10,
              fontSize: 22,
              color: "#a0aed3",
            }}
          >
            <i className="pf pf-credit-card" id="brand-icon"></i>
          </span>
        </label>
      </div>
      <div className="col-12 col-lg-6">
        <label className="w-100">
          Date d'expiration
          <CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
        </label>
      </div>
      <div className="col-12 col-md-6">
        <label className="w-100">
          CVC
          <CardCvcElement options={CARD_ELEMENT_OPTIONS} />
        </label>
      </div>
    </div>
  );
};

export default NewCBForm;
