const colors = {
  primary: "#dc142d",
  secondary: "#0e2b67",
  success: "#0f996d",
  info: "#a8c8f2",
  warning: "yellow",
  danger: "red",
  light: "#e4e7ec",
  dark: " black",
};

export default colors;
