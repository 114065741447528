import axios from "axios";
import { toast } from "react-toastify";
import { ERROR_TYPES } from "../context/reducers/errorReducers";

const tools = {
  formatSiren: (siren) => {
    var str = siren.toString().split("");
    return (
      str[0] +
      str[1] +
      str[2] +
      " " +
      str[3] +
      str[4] +
      str[5] +
      " " +
      str[6] +
      str[7] +
      str[8] +
      " " +
      str[9] +
      str[10] +
      str[11] +
      (str[12] || "") +
      (str[13] || "")
    );
  },
  setApiToken: (token) => {
    if (token) {
      // Apply to every request
      axios.defaults.headers.common["X-shop"] = token;
    } else {
      // Delete Api header
      delete axios.defaults.headers.common["X-shop"];
    }
  },
  setAuthToken: (token) => {
    if (token) {
      // Apply to every request
      axios.defaults.headers.common["Authorization"] = token;
    } else {
      // Delete Auth header
      delete axios.defaults.headers.common["Authorization"];
    }
  },
  isEmpty: (value) => {
    return (
      value === undefined ||
      value === null ||
      !value ||
      (typeof value === "object" && Object.keys(value).length === 0) ||
      (typeof value === "string" && value.trim().length === 0)
    );
  },
  truenumberWithSpaces: (x) => {
    x = isNaN(x) ? "" : x + "";
    x = x.replace(/\s/g, "");
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  },
  numberWithTrailingZero: (x) => {
    var y = x.split(",");
    if (y[1] && y[1].length < 2) {
      y[1] = y[1] + 0;
    }
    return y.join(",");
  },
  filterError: (errors, dispatch = null, type) => {
    var errorData = {};
    if (errors.response) {
      var status = errors.response.status;

      if (status == 404) {
        toast.error("La route n'a pas été trouvée");
      }

      if (errors.response.data) {
        if (errors.response.data.other) {
          toast.warning("Erreur : " + errors.response.data.other);
        } else {
          errorData = errors.response.data;
        }
      }
    } else {
      toast.error("Erreur interne : " + errors);
    }

    if (dispatch) {
      dispatch({ type: type, payload: false });
      dispatch({ type: ERROR_TYPES.GET_ERRORS, payload: errorData });
    }
    return errorData;
  },
  getFullname: (person) => {
    return person
      ? (person.gender ? (person.gender == 1 ? "Mr" : "Mme") : "") +
          " " +
          person.firstname +
          " " +
          person.lastname
      : "";
  },
  getFullAddress: (company) => {
    return company
      ? `
    ${company.address1} ${company.address2}, ${company.postalCode} ${company.city}
    `
      : "";
  },
  findIn: (arr, value, attribute) => {
    if (!arr || !value || !attribute) return {};

    var res = arr.find((d) => d[attribute] == value);

    if (res) {
      return res;
    }
    return {};
  },
  round: (value) => {
    if (!value) return 0;
    return Math.round(parseFloat(value) * 100) / 100;
  },
  getSelectedPMData: (formData) => {
    const { selectedExistingPaymentMethod, existingPaymentMethods } = formData;

    return selectedExistingPaymentMethod
      ? tools.findIn(existingPaymentMethods, selectedExistingPaymentMethod, "paymentMethodId")
      : null;
  },
  checkPMHasSub: (selectedPMData) => {
    return (
      selectedPMData &&
      selectedPMData.subscription?.chests &&
      selectedPMData.subscription.chests.length > 0
    );
  },
};
export default tools;
