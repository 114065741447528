import React from "react";
import tools from "../../../../helpers/tools";
import CheckCircle from "../../common/CheckCircle/CheckCircle";
import NextButton from "../../common/NextButton/NextButton";

const PriceCard = ({ price }) => {
  return (
    <fieldset className="mx-auto border px-3 rounded shadow-sm">
      <legend className="float-none py-1 px-2 bg-secondary text-white rounded" style={{ fontSize: 14, width: "auto" }}>
        Offre de lancement
      </legend>

      <div className="d-flex justify-content-center align-items-center ">
        <strong style={{ fontSize: "64px", fontWeight: "800" }}>{price}</strong>
        <span
          style={{
            fontSize: 16,
            paddingBottom: 15,
          }}
        >
          €/mois
        </span>
      </div>

      <div className="d-flex flex-column justify-content-center">
        <div className="d-flex align-items-center my-2">
          <CheckCircle style={{ position: "initial" }} />
          <span className="ms-3 d-block">Sans engagement. Résiliable à tout moment.</span>
        </div>
        <div className="d-flex align-items-center my-2">
          <CheckCircle style={{ position: "initial" }} />

          <span className="ms-3 d-block">Vos documents professionnels mis à jour automatiquement.</span>
        </div>
        <div className="d-flex align-items-center my-2">
          <CheckCircle style={{ position: "initial" }} />

          <span className="ms-3 d-block">Stockage sécurisé illimité de vos fichiers et partage avec les tiers.</span>
        </div>
        <NextButton />
      </div>
    </fieldset>
  );
};

export default PriceCard;
