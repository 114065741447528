import dataService from "../../helpers/dataService";

export const formActions = (state, dispatch) => {
  function test(userData) {
    alert("ok");
  }
  function updateErrors(errors) {
    dispatch({
      type: "UPDATE_FORM_ERRORS",
      payload: errors,
    });
  }

  function updateFormDataInput(e) {
    const { value, name, checked, type } = e.target;
    const newValue = type == "checkbox" ? checked : value;
    let overrideObject = { [name]: newValue };

    if (name == "paymentMode") {
      overrideObject = { ...overrideObject, selectedExistingPaymentMethod: "" };
    }

    dispatch({
      type: "UPDATE_FORM_DATA_INPUT",
      payload: overrideObject,
    });
  }

  function updateFormDataValue(overrideObject) {
    dispatch({
      type: "UPDATE_FORM_DATA_VALUE",
      payload: overrideObject,
    });
  }
  function updateFormStatusValue(overrideObject) {
    dispatch({
      type: "UPDATE_FORM_STATUS_VALUE",
      payload: overrideObject,
    });
  }

  function nextStep() {
    const { navigation } = state.formState;
    const currentStepIndex = navigation.steps.indexOf(navigation.currentStep);
    const nextStepIndex = currentStepIndex + 1;
    if (navigation.steps[nextStepIndex]) {
      const nextComponent = navigation.steps[nextStepIndex];
      dispatch({
        type: "UPDATE_STEP",
        payload: nextComponent,
      });
    }
  }
  function previousStep() {
    const { navigation } = state.formState;
    const currentStepIndex = navigation.steps.indexOf(navigation.currentStep);
    const nextStepIndex = currentStepIndex - 1;
    if (navigation.steps[nextStepIndex]) {
      const previousComponent = navigation.steps[nextStepIndex];
      dispatch({
        type: "UPDATE_STEP",
        payload: previousComponent,
      });
    }
  }
  function goToStep(idStep) {
    const { navigation } = state.formState;
    if (navigation.steps[idStep]) {
      const goToStepComponent = navigation.steps[idStep];
      console.log("goToStepComponent", goToStepComponent);
      dispatch({
        type: "UPDATE_STEP",
        payload: goToStepComponent,
      });
    }
  }

  function updateLoading(key, isLoading) {
    updateFormStatusValue({
      [key]: isLoading,
    });
  }

  function finishForm() {
    dispatch({
      type: "UPDATE_STATE",
      payload: { isPaymentDone: true },
    });
  }

  return {
    test,
    nextStep,
    previousStep,
    goToStep,
    updateFormDataInput,
    updateFormDataValue,
    updateFormStatusValue,
    updateLoading,
    updateErrors,
    finishForm,
  };
};
