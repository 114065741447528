import React from "react";
import Control from "../../../../common/Controls/Control";
import SaveBtn from "../../../../common/SaveBtn/SaveBtn";
import useBillingAddresses from "./useBillingAddresses";

const AddAddressForm = ({
  handleChangeAddress,
  newAddress,
  errors,
  addBillingAddress,
  editBillingAddress,
  isCreatingAddress,
  setIsAddingAddress,
}) => {
  const handleSubmit = () => {
    if (newAddress.id) {
      editBillingAddress();
    } else {
      addBillingAddress();
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        <Control
          label="Nom"
          type="text"
          name="name"
          value={newAddress.name}
          placeholder="Maison, bureau, ..."
          change={handleChangeAddress}
          error={errors}
        />
      </div>
      <div className="col-12 col-md-6">
        <Control
          label="Adresse"
          type="text"
          name="address1"
          value={newAddress.address1}
          change={handleChangeAddress}
          error={errors}
        />
      </div>
      <div className="col-12 col-md-6">
        <Control
          label="Adresse complément"
          type="text"
          name="address2"
          value={newAddress.address2}
          change={handleChangeAddress}
          error={errors}
        />
      </div>
      <div className="col-12 col-md-6">
        <Control
          label="Code postal"
          type="text"
          name="postalCode"
          value={newAddress.postalCode}
          change={handleChangeAddress}
          error={errors}
        />
      </div>
      <div className="col-12 col-md-6">
        <Control
          label="Ville"
          type="text"
          name="city"
          value={newAddress.city}
          change={handleChangeAddress}
          error={errors}
        />
      </div>
      <div className="col-12 col-md-6 offset-md-6">
        <div className="d-flex flex-row align-items-center flex-wrap w-100">
          <SaveBtn save={handleSubmit} isSaving={isCreatingAddress} />
          <button className="btn btn-link" onClick={() => setIsAddingAddress(false)}>
            Annuler
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddAddressForm;
