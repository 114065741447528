import { DateTime } from "luxon";
import React, { useEffect } from "react";
import tools from "../../../../../helpers/tools";
import Control from "../../../../common/Controls/Control";
import useFormState from "../../../hooks/useFormState";
import ExistingSubAlert from "./ExistingSubAlert/ExistingSubAlert";
import PaymentMethodButton from "./PaymentMethodButton/PaymentMethodButton";

const ExistingPaymentMethod = () => {
  const { formData, errors, actions } = useFormState();

  const { paymentMode, selectedExistingPaymentMethod, existingPaymentMethods } = formData;

  useEffect(() => {
    actions.updateFormDataValue({
      selectedExistingPaymentMethod: "",
    });
  }, []);

  const CBDatas = existingPaymentMethods.filter((pm) => pm.type == "CB");
  const SEPADatas = existingPaymentMethods.filter((pm) => pm.type == "SEPA");

  const selectedPMData = tools.getSelectedPMData(formData);

  return (
    <div className="mb-4">
      {paymentMode == "CB" && CBDatas.length > 0 && (
        <PaymentMethodButton
          label="Utiliser une carte enregistrée"
          type="btnList"
          dataIndex="paymentMethodId"
          dataLabel="info"
          dataLabel2="expireAt"
          name="selectedExistingPaymentMethod"
          value={selectedExistingPaymentMethod}
          datas={CBDatas}
          change={actions.updateFormDataInput}
          error={errors}
        />
      )}
      {paymentMode == "SEPA" && SEPADatas.length > 0 && (
        <PaymentMethodButton
          label="Utiliser un IBAN enregistré"
          type="btnList"
          dataIndex="paymentMethodId"
          dataLabel="name"
          name="selectedExistingPaymentMethod"
          value={selectedExistingPaymentMethod}
          datas={SEPADatas}
          change={actions.updateFormDataInput}
          error={errors}
          buttonStyle={{ maxWidth: "100%" }}
        />
      )}

      {tools.checkPMHasSub(selectedPMData) && <ExistingSubAlert selectedPMData={selectedPMData} />}
    </div>
  );
};

export default ExistingPaymentMethod;
