import React, { useEffect } from "react";
import Control from "../../../../common/Controls/Control";
import AddAddressForm from "./AddAddressForm";
import useBillingAddresses from "./useBillingAddresses";

const BillingAddresses = () => {
    const {
        formData,
        actions,
        errors,
        getBillingAddresses,
        isAddingAddress,
        setIsAddingAddress,
        handleChangeAddress,
        newAddress,
        addBillingAddress,
        editBillingAddress,
        isCreatingAddress,
        handleModifyAddress,
    } = useBillingAddresses();

    useEffect(() => {
        getBillingAddresses();
    }, []);

    return (
        <div>
            {isAddingAddress ? (
                <AddAddressForm
                    handleChangeAddress={handleChangeAddress}
                    newAddress={newAddress}
                    errors={errors}
                    addBillingAddress={addBillingAddress}
                    editBillingAddress={editBillingAddress}
                    isCreatingAddress={isCreatingAddress}
                    setIsAddingAddress={setIsAddingAddress}
                />
            ) : (
                <>
                    <Control
                        label="Choisissez une adresse de facturation"
                        type="btnList"
                        name="selectedBillingAddress"
                        dataIndex="id"
                        dataLabel="name"
                        dataLabel2="addressFull"
                        value={formData.selectedBillingAddress}
                        change={actions.updateFormDataInput}
                        datas={formData.billingAddresses.map((address) => ({
                            addressFull: `${address.address1} ${address.address2} ${address.postalCode} ${address.city}`,
                            ...address,
                        }))}
                        errors={errors}
                    />
                    <div className="d-flex flex-row flex-wrap justify-content-md-between align-items-center justify-content-start">
                        {formData.selectedBillingAddress && (
                            <button className="btn btn-link text-secondary px-0" onClick={handleModifyAddress}>
                                <i className="fa fa-edit me-2" />
                                Corriger l'adresse sélectionnée
                            </button>
                        )}
                        <button className="btn btn-link px-0" onClick={() => setIsAddingAddress(true)}>
                            <i className="fa fa-plus me-2" />
                            Ajouter une adresse de facturation
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

export default BillingAddresses;
