import { formActions } from "../../../context/actions/formActions";
import useStore from "../../../context/useStore";

const useFormState = () => {
  const [state, dispatch] = useStore();
  const actions = formActions(state, dispatch);
  const { formState } = state;
  const { navigation, formData, errors, formStatus } = state.formState;

  return {
    state,
    dispatch,
    formState,
    formStatus,
    actions,
    navigation,
    formData,
    errors,
    constants: state.constants.items,
  };
};

export default useFormState;
