import { useAtom } from "jotai";
import { useState } from "react";
import { isPriceLoadingAtom, priceAtom, promoCodeAtom } from "../../../../atoms/atoms";
import dataService from "../../../../helpers/dataService";
import useFormState from "../../hooks/useFormState";

const usePrices = () => {
  const { formData } = useFormState();

  const [prices, setPrices] = useAtom(priceAtom);
  const [promoCode, setPromoCode] = useAtom(promoCodeAtom);
  const [isPriceLoading, setIsPriceLoading] = useAtom(isPriceLoadingAtom);
  const [promoCodeError, setPromoCodeError] = useState({});
  const [unavailableCompanies, setUnavailableCompanies] = useState([]);

  const getPrices = (emptyPromoCode = false) => {
    var subscriptionId = null;
    if (formData?.selectedExistingPaymentMethod) {
      var pms = formData.existingPaymentMethods.find(
        (p) => p.paymentMethodId == formData.selectedExistingPaymentMethod
      );
      if (pms) {
        subscriptionId = pms.subscription?.id;
      }
    }
    if (emptyPromoCode) setPromoCode("");
    setIsPriceLoading(true);
    setPromoCodeError({});
    dataService.post(
      `payments/prices`,
      {
        companies: formData.selectedCompanies,
        subscriptionId,
        promoCode: emptyPromoCode ? "" : promoCode,
      },
      (datas) => {
        setPrices(datas);

        setUnavailableCompanies(
          formData.selectedCompanies.filter((c) => !datas.detail.find((de) => de.siren == c.siren))
        );
      },
      (err) => {
        setPromoCodeError(err);
        setPromoCode("");
      },
      () => setIsPriceLoading(false)
    );
  };

  const removePromoCode = () => {
    getPrices(true);
  };

  const isPromoCodeValid = promoCode && prices.total != prices.totalWithoutPromo;

  return {
    getPrices,
    prices,
    unavailableCompanies,
    promoCode,
    setPromoCode,
    isPriceLoading,
    isPromoCodeValid,
    promoCodeError,
    removePromoCode,
  };
};

export default usePrices;
