import React from "react";
import useFormState from "../../hooks/useFormState";
import LoginButton from "./LoginButton";

const AutoConnect = () => {
  const { formData } = useFormState();

  return (
    formData.userAccountData && <LoginButton autoConnect={true}></LoginButton>
  );
};

export default AutoConnect;
