import React from "react";

const StepTitle = ({ text, subText }) => {
  return (
    <div>
      <div>
        <strong style={{ fontSize: 30, lineHeight: "39px" }} className="text-secondary">
          {text}
        </strong>
      </div>
      <div>
        <p className="mb-0 text-secondary">{subText}</p>
      </div>
    </div>
  );
};

export default StepTitle;
